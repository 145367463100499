import { Box, Flex } from 'grid-styled'
import * as React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'

import CTA from '../components/CTA'
import Header from '../components/Header'
import FAQs from '../components/FAQs'
import { Content } from '../components/procedures'
import styled from 'styled-components'

const Links = styled.ul`
  list-style: none;
  padding-left: 0;

  li {
    margin-bottom: 0.5rem;
  }
  a {
    font-weight: 500;
    color: ${props => props.theme.colors.primary};
    /* text-decoration: underline; */
  }
`

export default () => (
  <Layout>
    <Helmet>
      <title>Media &amp; Publicity - Plastic Surgery Sydney</title>
      <meta
        name="description"
        content="Take a look over our recent publicity and media appearances. Podcasts, articles, videos and more..."
      />
    </Helmet>
    <Header>
      <h1>Media &amp; Publicity</h1>
    </Header>
    <Content style={{ marginTop: 100 }}>
      <h2>The Latest News</h2>
      <p>
        To discover more about Plastic Surgery procedures, please browse through
        the Media area of our website.
      </p>

      <h4>Visit our Plastic Surgery News Centre</h4>

      <p>
        Keep up to date with the latest Plastic Surgery news and updates. Please
        read through our Plastic Surgery news articles for the latest treatment
        information on Breast Augmentation and Reduction, Facial Cosmetic
        Surgery and Body Surgery as well as Non-Surgical
        Anti-Wrinkle treatments.
      </p>

      <h2>Browse Helpful Links</h2>

      <p>
        If you want to find out more about Plastic Surgery procedures online,
        Dr. Sellars recommends these Helpful Links to our patients. Visit the
        Royal Australasian College of Surgeons, Australian Society of Plastic
        Surgeons (ASPS), International Confederation for Plastic, Reconstructive
        and Aesthetic Surgery (IPRAS) and lots, lots more!
      </p>

      <Links>
        <h3>Articles</h3>
        <li>
          <a target="_blank" href="
https://journals.lww.com/plasreconsurg/Fulltext/2018/03000/Abdominoplasty_Improves_Low_Back_Pain_and_Urinary.13.aspx">
            American Journal PRS article - BEST PAPER 2019 “Abdominoplasty Improves Low Back Pain and Urinary Incontinence"
          </a>
        </li>
        <li>
          <a target="_blank" href="http://www.plasticsurgeryhub.com.au/hear-hear-its-all-about-the-ear/">
            Hear Hear, It's all About the Ear
          </a>
        </li>
        <li>
          <a
            href="https://www.plasticsurgeryhub.com.au/sculpted-face-without-surgery/
"
          >
            The Sculpted Face, Without Surgery – What Fillers Can Do
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.plasticsurgeryhub.com.au/earlobe-repairs-on-the-rise/">
            Earlobe Repairs on the Rise
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.plasticsurgeryhub.com.au/dermal-fillers-where-why-who-how/">
            Treatments for Wrinkles and Volume Loss – Where, why, who, how?
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.plasticsurgeryhub.com.au/hot-weather-perfect-for-coolsculpting/">
            Hot Weather perfect for Coolsculpting!
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.plasticsurgeryhub.com.au/tonga-plastic-surgery-dr-graham-sellars/">
            Trip to Tonga – Life-Changing Plastic Surgery with Dr Graham Sellars
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.plasticsurgeryhub.com.au/the-team-at-dr-sellars-san-clinic-wahroonga/">
            Introducing the Team at Dr Sellars San Clinic Wahroonga
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.plasticsurgeryhub.com.au/importance-of-a-great-injector/">
            The Importance of A Great Injector
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.plasticsurgeryhub.com.au/treat-range-skin-issues-ipl-laser/">
            Treat a range of Skin Issues with IPL and Laser
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.plasticsurgeryhub.com.au/commonly-asked-plastic-surgery-questions/">
            Commonly Asked Plastic Surgery Questions
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.plasticsurgeryhub.com.au/dangers-overseas-surgery/">
            Dangers of Overseas Surgery
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://www.plasticsurgeryhub.com.au/alisons-breast-reduction/"
          >
            Alison's Breast Reduction
          </a>
        </li>
        <br />
        <h3>Radio and Podcasts</h3>
        <li>
          <a
            target="_blank"
            href="/assets/2UE-Weekend-Afternoon-segment-SAN-Hospital-12-07-14.mp3"
          >
            Radio Interview on 2UE
          </a>
        </li>
        <li>
          <a target="_blank" href="/assets/sellars-interview-2018.mp3">
            Dr Sellars interview on Plastic Surgery Hub
          </a>
        </li>
        <li>
          <a target="_blank" href="/assets/alison-podcast.mp3">
            Alison's Breast Reduction Interview
          </a>
        </li>
      </Links>
    </Content>
    <CTA
      title="Having an issue?"
      subtitle="We’re here to help you."
      button="Contact Us"
    />
  </Layout>
)
